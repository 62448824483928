import { Brand } from "./components/brand";
import { BrandType } from "./components/brand/const";
import './App.temp.scss';

function App() {
    return (
        <div className='temp_app'>
            <Brand type={BrandType.LogoWithTextUnder} />
            <h1>
                Become a fantasy expert. Build a legendary record.
            </h1>
            <p>
                Think you know fantasy sports? Predict the winner of head to head player matchups.
                Follow your favorite fantasy sports experts and get help from the community to figure out who
                you should start in your weekly lineup!
            </p>
            <h2>
                UNDER CONSTRUCTION - CHECK BACK SOON!
            </h2>
        </div>
    )
}

export default App;